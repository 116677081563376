import ArrayFormatterMixin from "./array-formatter-mixin"
import { isFunction, toLower, toUpper, camelCase, capitalize, upperFirst, kebabCase, snakeCase, trim, curryRight, get } from "lodash"
import { formatNumber, formatDecimal, formatCurrency, formatPercentage } from "@/plugins/numeral"
import { formatDate, formatTime, parseDuration } from "@/plugins/moment"
import { formatBoolean, formatDuration } from "@/libs/utils"

function isNumeralFormat(test) {
	const match = /^numeral:(.+)$/g.exec(test)
	if(match) return match[1]
	else return false
}

function isMomentFormat(test) {
	const match = /^moment:(.+)$/g.exec(test)
	if(match) return match[1]
	else return false
}

function formatFunction(format) {
	if(isFunction(format)) return format
	let numeralFormat, momentFormat
	if((numeralFormat = isNumeralFormat(format))) return curryRight(formatNumber, 2)(numeralFormat)
	if((momentFormat = isMomentFormat(format))) return curryRight(formatDate, 2)(momentFormat)
	switch(toLower(format)) {
		case "uppercase": return toUpper
		case "lowercase": return toLower
		case "camelcase": return camelCase
		case "capitalize": return capitalize
		case "upperfirst": return upperFirst
		case "kebabcase": return kebabCase
		case "snakecase": return snakeCase
		case "trim": return trim
		case "decimal": return formatDecimal
		case "currency": return formatCurrency
		case "time": return formatTime
		case "boolean": return formatBoolean
		case "duration": return formatDuration
		case "percentage": return formatPercentage
		case "int-percentage": return curryRight(formatPercentage, 2)(true)
		default: return value => get(value, format, value)
	}
}

export default {
	mixins: [ArrayFormatterMixin],
	props: {
		format: [Function, String]
	},
	methods: {
		translate(value) {
			if(this._.isArray(value)) return this.formatArray(value, formatFunction(this.format))
			else return formatFunction(this.format)(value)
		},
		async asyncTranslate(value) {
			if(this._.isArray(value)) return await this.asyncFormatArray(value, formatFunction(this.format))
			else return await formatFunction(this.format)(value)
		}
	}
}