import Vue from "vue"
import it from "moment/locale/it"
import moment from "moment"
import { isNil } from "lodash"

export function formatDate(date, format = "DD-MM-YYYY", fallback = "") {
	if(isNil(date)) return fallback
	return moment(date).format(format)
}

export function formatDuration(duration, fallback = "") {
	if(isNil(duration)) return fallback
	return moment.duration(duration).humanize()
}

export function extendedDate(date) {
	return formatDate(date, "DD MMMM YYYY")
}

export function formatTime(date) {
	return formatDate(date, "HH:mm:ss")
}

export function parseDuration(iso8601Duration) {
	return moment.duration(iso8601Duration)
}

const VueMoment = {
	install(Vue) {
		moment.updateLocale("it", it)
		Object.defineProperty(Vue.prototype, "$moment", { get() { return moment } })
		Vue.moment = moment
		Vue.filter("moment", formatDate)
		Vue.filter("formatDate", extendedDate)
		Vue.filter("formatTime", formatTime)
	}
}

Vue.use(VueMoment)

