import Vue from "vue"
import numeral from "numeral"
import "numeral/locales"
import { isNil } from "lodash"

export function formatNumber(number, format = undefined) {
	if(isNil(number)) return ""
	return numeral(number).format(format)
}

export function formatDecimal(number, decimals = 2) {
	const format = `0,0.${"0".repeat(decimals < 0 ? 0 : decimals)}`
	return formatNumber(number, format)
}

export function formatCurrency(number) {
	return formatNumber(number, "0,0.00 $")
}

export function formatPercentage(number, asInt = false) {
	const percentage = isNil(number) ? 0 : number / 100
	if(asInt) return formatNumber(percentage, "0,0 %")
	return formatNumber(percentage, "0,0.00 %")
}

const VueNumeral = {
	install(Vue) {
		numeral.locale("it")
		Vue.numeral = numeral
		Object.defineProperty(Vue.prototype, "$numeral", { get() { return numeral } })
		Vue.filter("numeral", formatNumber)
		Vue.filter("decimal", formatDecimal)
		Vue.filter("currency", formatCurrency)
	}
}

Vue.use(VueNumeral)